import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 2560.000000 2560.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,2560.000000) scale(0.100000,-0.100000)">



<path d="M0 12800 l0 -12800 12800 0 12800 0 0 12800 0 12800 -12800 0 -12800
0 0 -12800z m13145 4176 c148 -27 318 -68 334 -82 7 -6 1 -11 -392 -312 -67
-51 -137 -105 -156 -120 -57 -45 -56 -45 -128 -2 -111 68 -340 176 -453 213
-165 55 -290 77 -434 77 -69 0 -126 4 -126 8 0 21 315 142 472 181 192 48 317
61 563 57 152 -3 260 -9 320 -20z m705 -242 c103 -56 233 -142 321 -212 134
-106 370 -352 369 -383 -1 -8 -30 -36 -67 -64 -36 -27 -131 -101 -212 -163
-80 -61 -189 -145 -241 -184 -52 -40 -97 -75 -100 -78 -3 -3 -21 -17 -40 -30
-19 -14 -44 -32 -54 -42 -11 -10 -25 -18 -31 -18 -7 0 -32 26 -57 58 -177 224
-474 516 -680 667 -38 27 -68 52 -68 56 0 4 33 33 73 63 222 171 374 288 391
302 10 9 54 42 97 74 l79 59 72 -32 c40 -18 107 -50 148 -73z m-1775 -119
c153 -24 286 -69 461 -155 154 -75 215 -112 212 -127 -2 -7 -70 -64 -153 -128
-82 -64 -157 -121 -165 -128 -9 -7 -51 -39 -95 -73 -44 -33 -116 -88 -160
-122 l-80 -61 -27 27 c-15 15 -101 124 -192 242 -91 118 -169 220 -173 225
-45 54 -163 214 -163 218 0 18 144 73 228 87 76 13 210 11 307 -5z m-508 -331
c70 -91 157 -202 192 -247 35 -45 74 -95 86 -112 13 -16 49 -62 79 -102 31
-39 56 -76 56 -82 0 -10 -15 -22 -370 -295 -113 -86 -213 -163 -222 -171 -40
-33 -70 -47 -78 -39 -13 13 -66 244 -84 365 -36 242 -10 484 69 657 32 68 119
192 136 192 4 0 65 -75 136 -166z m-412 -31 c-3 -10 -18 -56 -33 -103 -25 -81
-26 -95 -27 -320 0 -270 19 -399 91 -618 23 -69 20 -77 -58 -136 -263 -200
-392 -298 -451 -345 -39 -30 -75 -50 -83 -47 -17 6 -18 87 -4 278 32 440 197
861 470 1202 30 37 61 77 69 87 17 24 35 25 26 2z m1820 -62 c81 -63 97 -76
189 -156 89 -76 338 -326 332 -332 -2 -2 -43 21 -90 52 -272 178 -587 236
-892 165 -48 -11 -91 -19 -95 -18 -4 2 96 83 224 180 260 199 228 189 332 109z
m1677 -198 c93 -140 181 -324 242 -506 62 -185 112 -478 88 -513 -4 -5 -44
-38 -90 -72 -45 -34 -111 -84 -146 -111 -245 -190 -369 -282 -377 -279 -5 2
-32 59 -59 128 -74 184 -186 404 -297 580 -54 86 -108 169 -120 184 -13 16
-23 36 -22 45 0 9 35 41 77 72 76 56 240 181 377 287 39 29 113 86 165 125 52
40 101 78 108 85 20 18 27 15 54 -25z m-1807 -266 l17 -33 118 4 c106 4 128 2
211 -22 137 -40 227 -94 335 -200 184 -184 274 -411 274 -697 0 -146 -29 -283
-86 -407 -138 -301 -408 -492 -696 -492 -49 0 -88 3 -88 6 0 3 15 23 33 44 28
35 37 40 77 40 135 1 301 75 420 187 125 117 209 272 245 453 23 114 16 323
-14 427 -46 158 -122 285 -235 390 -130 121 -284 183 -454 183 -76 0 -83 -2
-77 -17 3 -10 7 -22 8 -28 2 -11 -215 -30 -239 -21 -10 4 3 32 54 111 37 57
70 105 73 105 3 0 14 -15 24 -33z m-235 -29 c0 -7 -13 -27 -29 -44 -25 -27
-37 -31 -117 -41 -299 -38 -536 -270 -621 -609 -26 -104 -23 -333 6 -439 80
-296 288 -514 551 -575 58 -14 204 -19 215 -8 4 3 2 16 -5 27 -6 12 -7 21 -3
22 4 0 16 2 26 4 10 2 64 4 120 6 80 2 101 -1 99 -11 -4 -19 -123 -200 -131
-200 -3 0 -14 12 -23 28 l-17 27 -133 1 c-121 0 -141 3 -223 31 -50 17 -119
48 -155 70 -86 50 -209 169 -266 255 -186 283 -214 677 -69 982 145 306 398
479 708 485 48 1 67 -3 67 -11z m-801 -285 c-66 -110 -127 -264 -149 -383 -33
-169 -19 -388 34 -555 14 -43 24 -81 22 -83 -4 -4 -78 113 -131 208 -86 154
-225 456 -225 490 0 4 70 60 156 126 86 65 189 145 228 176 39 31 75 57 79 57
5 1 -1 -16 -14 -36z m509 -80 c17 -13 32 -29 32 -36 0 -7 -16 -35 -36 -62 -47
-65 -101 -173 -125 -249 -24 -74 -29 -208 -10 -286 17 -71 78 -193 130 -262
23 -30 41 -59 41 -64 0 -11 -75 -74 -87 -74 -11 0 -92 112 -130 180 -18 30
-44 91 -59 135 -25 70 -28 95 -28 205 -1 104 3 137 22 200 48 151 174 361 206
343 6 -3 26 -17 44 -30z m1024 20 c116 -133 208 -366 208 -524 0 -164 -77
-363 -191 -492 l-36 -41 -41 34 c-23 18 -42 39 -42 47 0 7 20 39 44 70 25 32
60 90 79 128 102 210 78 407 -79 646 -24 36 -44 70 -44 75 0 9 70 73 81 74 3
0 13 -8 21 -17z m558 -175 c153 -240 374 -713 355 -759 -4 -8 -28 -30 -55 -49
-94 -68 -595 -455 -598 -462 -8 -17 -38 -5 -60 23 l-24 29 75 80 c139 148 226
307 276 502 65 252 44 489 -64 743 -44 101 -24 79 95 -107z m-1434 -2 c44 -39
46 -29 -24 -141 -35 -56 -62 -148 -62 -210 0 -66 27 -139 80 -217 l49 -73 -37
-37 c-20 -20 -42 -37 -49 -38 -19 0 -88 96 -125 175 -32 68 -33 74 -33 195 0
116 2 130 29 190 34 78 106 190 121 190 7 0 29 -15 51 -34z m731 12 c38 -42
81 -123 108 -203 24 -70 27 -91 23 -175 -6 -111 -27 -175 -91 -270 -60 -90
-68 -93 -116 -45 l-40 40 49 73 c105 155 106 284 4 448 -24 40 -44 76 -44 82
0 10 69 72 81 72 3 0 15 -10 26 -22z m-553 -129 c26 -21 34 -53 16 -64 -18
-11 -59 -102 -66 -144 -11 -66 4 -125 47 -191 l40 -61 -32 -30 -32 -30 -21 20
c-12 11 -37 51 -56 88 -33 63 -35 74 -35 163 0 85 3 101 29 155 31 64 65 115
77 115 3 0 18 -9 33 -21z m367 -16 c63 -99 74 -133 74 -233 0 -85 -3 -101 -29
-150 -15 -31 -40 -71 -55 -89 l-27 -33 -32 31 -33 30 35 51 c75 109 75 205 1
320 l-35 55 30 28 c17 15 34 27 39 27 5 0 19 -17 32 -37z m-1721 -125 c86
-217 295 -583 453 -793 l68 -90 -34 -30 c-19 -16 -155 -122 -303 -235 -148
-112 -271 -207 -274 -210 -23 -27 -134 -101 -148 -98 -48 9 -262 386 -331 583
-51 144 -52 146 -71 230 -38 166 -47 223 -38 238 4 9 59 54 121 101 61 47 148
114 193 148 45 35 107 83 140 108 32 25 83 64 112 88 29 23 59 42 66 42 8 0
28 -37 46 -82z m1567 -10 c31 -15 56 -72 48 -108 -27 -124 -205 -111 -205 15
0 88 76 133 157 93z m2140 -225 c-19 -382 -143 -758 -358 -1085 -94 -143 -203
-277 -216 -265 -3 4 1 26 10 49 44 122 52 186 52 413 -1 228 -4 254 -61 502
-20 85 -24 78 84 160 42 33 131 100 197 151 66 50 154 118 195 151 41 33 81
60 89 60 12 1 13 -22 8 -136z m-672 -478 c24 -102 55 -346 55 -429 0 -127 -36
-306 -85 -421 -22 -51 -96 -164 -123 -188 -20 -18 -21 -18 -40 5 -25 29 -96
120 -209 268 -48 63 -127 165 -175 225 -49 61 -88 117 -88 125 0 9 50 54 112
100 61 47 116 89 122 95 6 5 49 39 96 74 47 36 112 85 145 111 139 107 158
121 166 113 4 -4 15 -39 24 -78z m-2355 -357 c196 -222 498 -493 683 -611 15
-10 27 -21 27 -26 0 -5 -26 -28 -57 -52 -32 -24 -60 -46 -63 -49 -3 -3 -21
-17 -40 -30 -19 -13 -58 -42 -86 -64 -28 -23 -66 -52 -84 -66 -18 -14 -38 -30
-44 -35 -6 -6 -64 -50 -128 -98 -126 -95 -119 -93 -203 -60 -198 79 -427 212
-577 335 -94 76 -258 240 -258 258 0 8 46 49 101 90 56 41 110 82 119 90 10 8
78 61 152 117 73 56 185 142 248 192 63 49 120 90 126 90 6 1 44 -36 84 -81z
m192 19 c159 -102 323 -159 514 -177 86 -8 237 0 319 16 118 23 300 96 387
156 37 25 51 23 72 -14 l19 -32 -34 -29 c-19 -15 -110 -87 -204 -158 -93 -71
-231 -177 -305 -234 -74 -58 -138 -105 -141 -105 -11 0 -184 126 -284 206
-108 86 -297 265 -379 358 -32 36 -55 66 -52 66 3 0 43 -24 88 -53z m1498
-284 c52 -69 100 -131 105 -137 6 -6 49 -60 95 -121 46 -60 105 -138 132 -171
26 -34 48 -68 48 -77 0 -30 -142 -81 -270 -97 -206 -25 -479 39 -748 174 -125
63 -126 64 -50 122 89 67 142 108 148 114 3 3 23 19 45 36 22 17 72 55 110 84
193 150 261 199 275 199 8 0 58 -56 110 -126z m-690 -530 c261 -131 446 -182
678 -191 163 -5 164 -6 45 -66 -98 -50 -317 -121 -465 -151 -228 -46 -496 -55
-723 -25 -134 18 -285 52 -285 65 0 7 46 48 103 92 242 189 472 363 477 361 3
-2 79 -40 170 -85z m4345 -2223 l0 -915 -137 -3 -138 -3 0 914 c0 503 3 917 7
921 4 3 66 5 137 4 l131 -3 0 -915z m1403 6 c2 -721 0 -911 -10 -918 -17 -10
-249 -10 -265 0 -8 5 -13 41 -15 99 l-3 92 -45 -56 c-89 -111 -206 -164 -360
-164 -151 0 -260 44 -352 144 -108 116 -150 248 -151 467 -1 176 15 260 74
380 72 146 192 248 336 285 73 19 202 19 273 0 68 -18 154 -76 197 -133 l33
-44 0 375 c0 205 3 377 7 381 4 3 68 5 142 4 l136 -3 3 -909z m-11008 789
c259 -49 430 -147 537 -308 102 -152 138 -305 130 -547 -9 -266 -73 -425 -235
-587 -159 -158 -357 -241 -629 -263 -156 -12 -570 -13 -577 -1 -4 6 -9 1487
-6 1725 0 4 155 6 348 3 262 -4 368 -9 432 -22z m5221 -610 c87 -346 163 -663
170 -703 l12 -72 34 132 c18 73 49 185 67 248 18 63 55 192 81 285 136 493
187 673 198 705 l13 35 149 0 149 0 77 -305 c75 -297 96 -378 195 -755 26 -99
54 -218 64 -265 l17 -85 7 45 c7 45 37 173 191 805 46 187 93 383 105 435 33
141 18 130 180 130 76 0 141 -4 144 -9 3 -5 -3 -40 -14 -77 -18 -62 -176 -637
-279 -1019 -22 -82 -52 -190 -66 -240 -14 -49 -41 -148 -60 -220 -53 -196 -29
-175 -204 -175 -157 0 -181 3 -181 26 0 13 -31 130 -236 879 -47 170 -91 344
-99 385 -7 41 -14 66 -14 55 -1 -29 -47 -207 -141 -550 -45 -165 -101 -372
-125 -460 -68 -253 -88 -319 -99 -327 -20 -14 -312 -9 -324 5 -5 6 -58 185
-117 397 -58 212 -117 421 -130 465 -12 44 -53 188 -89 320 -37 132 -83 296
-103 365 -20 69 -38 137 -41 153 l-4 28 158 -3 158 -3 157 -630z m-2829 315
l3 -175 145 -5 145 -5 0 -105 0 -105 -147 -3 -148 -3 0 -340 c0 -363 2 -383
52 -436 31 -33 118 -49 171 -30 77 26 72 31 75 -68 2 -49 1 -99 -2 -111 -9
-34 -75 -49 -221 -49 -115 0 -136 3 -183 24 -61 27 -135 104 -154 159 -8 26
-14 162 -18 442 l-5 405 -102 3 -103 3 0 109 0 109 103 3 102 3 3 142 3 141
67 20 c37 11 96 29 132 39 36 11 68 17 72 14 4 -4 9 -85 10 -181z m-907 -165
c128 -34 215 -96 261 -187 51 -101 54 -141 52 -620 l-3 -443 -124 -3 c-68 -1
-128 1 -133 6 -5 5 -9 45 -10 90 0 45 -4 82 -8 82 -4 0 -16 -12 -26 -27 -72
-111 -195 -173 -343 -173 -183 0 -297 62 -362 196 -18 37 -24 70 -27 140 -7
145 30 241 120 318 86 75 148 95 403 131 264 37 239 26 232 96 -13 148 -130
219 -316 192 -98 -15 -169 -42 -253 -97 l-68 -45 0 124 0 124 53 24 c71 34
166 65 229 76 89 15 258 12 323 -4z m2015 -9 c124 -45 203 -115 240 -214 36
-95 41 -172 38 -607 l-3 -420 -115 -3 c-169 -4 -160 -10 -160 101 l0 92 -19
-30 c-60 -97 -182 -172 -298 -185 -186 -19 -340 50 -411 185 -25 47 -27 58
-27 180 0 121 2 133 26 180 35 64 99 130 158 160 82 41 174 61 441 95 133 17
130 16 130 64 0 64 -43 142 -97 176 -114 73 -358 33 -505 -82 -21 -16 -38 -26
-39 -21 0 4 -1 60 -1 123 l0 115 38 23 c45 26 174 69 248 82 79 14 303 5 356
-14z m4450 -11 c186 -68 299 -196 351 -394 29 -108 29 -306 0 -414 -44 -166
-147 -300 -287 -373 -90 -47 -144 -63 -264 -74 -334 -32 -596 139 -677 441
-26 97 -23 300 5 402 34 118 76 193 151 267 137 135 275 182 511 176 118 -3
145 -7 210 -31z m1258 14 c21 -8 22 -13 22 -139 0 -71 -4 -130 -9 -130 -5 0
-26 7 -47 16 -49 20 -154 15 -204 -11 -66 -33 -128 -118 -161 -220 -9 -28 -14
-148 -18 -400 l-6 -360 -135 0 -135 0 -3 618 -2 617 140 0 140 0 0 -122 1
-123 31 65 c51 105 141 181 238 200 38 8 111 2 148 -11z m-4550 -572 l3 -107
-328 2 -328 3 -3 94 c-1 52 0 101 2 108 5 11 64 13 328 11 l323 -3 3 -108z
m-278 -957 c24 -24 25 -48 4 -78 -19 -28 -67 -29 -94 -2 -23 23 -24 30 -9 64
18 39 68 47 99 16z m-380 -125 l0 -65 56 0 55 0 -3 -42 -3 -43 -52 1 -53 0 0
-126 c0 -152 9 -174 71 -168 l39 4 0 -43 0 -42 -46 -7 c-59 -8 -117 9 -143 43
-19 23 -21 39 -21 182 l0 156 -37 0 c-37 0 -38 1 -41 43 l-3 42 40 0 40 0 3
52 c3 52 4 53 43 64 22 7 43 13 48 13 4 1 7 -28 7 -64z m-3387 -70 c37 -6 37
-6 37 -55 0 -54 -3 -56 -40 -35 -38 22 -102 19 -141 -8 -88 -59 -92 -204 -7
-257 26 -17 44 -20 88 -16 30 2 65 7 78 11 22 7 23 6 20 -36 -3 -42 -4 -44
-48 -57 -52 -15 -147 -10 -188 12 -37 20 -89 75 -102 110 -16 42 -14 157 3
197 32 78 93 126 176 138 47 7 57 7 124 -4z m8189 -32 c40 -40 47 -82 48 -266
l0 -158 -52 3 -53 3 -5 168 c-5 162 -6 170 -29 188 -45 37 -116 16 -147 -42
-10 -21 -14 -66 -14 -174 l0 -145 -50 0 -50 0 0 230 0 230 50 0 50 0 0 -37 0
-37 33 32 c59 57 164 60 219 5z m-7645 26 c38 -14 81 -56 106 -104 29 -57 29
-174 -1 -232 -82 -158 -336 -162 -413 -7 -19 39 -24 65 -24 124 1 108 41 178
125 214 41 18 166 21 207 5z m424 -1 c1 -7 1 -21 0 -30 -3 -22 12 -23 30 -2
23 28 67 44 119 44 68 0 103 -20 129 -73 19 -39 21 -61 21 -215 l0 -172 -49 0
-50 0 -3 159 c-3 144 -5 162 -24 188 -24 32 -73 42 -109 23 -47 -25 -58 -63
-61 -219 l-3 -146 -51 -3 -50 -3 0 230 0 231 50 0 c33 0 50 -4 51 -12z m639
-24 l0 -37 25 27 c32 35 64 46 132 46 49 0 59 -4 88 -33 46 -46 55 -89 55
-269 l0 -158 -55 0 -55 0 0 156 c0 156 -5 185 -39 211 -22 17 -72 16 -99 -3
-44 -31 -52 -63 -52 -219 l0 -146 -52 3 c-29 2 -52 6 -50 11 1 4 2 106 2 227
l0 220 50 0 50 0 0 -36z m828 22 c67 -35 102 -101 102 -192 l0 -54 -155 0
c-100 0 -155 -4 -155 -10 0 -6 7 -26 15 -46 29 -70 137 -92 229 -45 l36 19 0
-45 c0 -41 -2 -45 -37 -60 -24 -10 -68 -17 -118 -17 -97 -1 -145 20 -190 83
-29 41 -30 48 -30 144 0 99 1 104 32 148 43 62 98 89 181 89 35 0 75 -6 90
-14z m610 8 c19 -6 22 -13 22 -55 0 -27 -2 -49 -4 -49 -2 0 -17 7 -32 15 -69
36 -160 6 -197 -65 -28 -55 -18 -144 21 -183 38 -38 121 -49 173 -23 17 9 34
16 36 16 1 0 3 -20 3 -43 0 -38 -4 -45 -27 -56 -116 -53 -277 7 -314 117 -17
48 -16 137 0 185 17 52 67 106 116 128 40 19 154 26 203 13z m732 -224 l0
-230 -50 0 -50 0 0 230 0 230 50 0 50 0 0 -230z m350 193 c0 -35 0 -36 18 -16
37 41 65 53 128 53 52 0 67 -4 93 -26 48 -40 61 -102 61 -281 l0 -153 -55 0
-55 0 0 156 c0 156 -5 185 -39 211 -29 22 -78 15 -113 -16 l-33 -29 -3 -161
-3 -161 -55 0 -54 0 0 223 c0 123 3 227 7 230 3 4 28 7 55 7 l48 0 0 -37z
m801 20 c16 -10 29 -21 29 -25 0 -4 5 -8 10 -8 6 0 10 11 10 26 0 24 2 25 53
22 l52 -3 -1 -250 0 -250 -27 -52 c-40 -78 -88 -105 -196 -110 -56 -3 -102 0
-131 9 -44 14 -45 15 -48 61 -2 26 -2 47 0 47 2 0 27 -9 56 -20 82 -31 161
-22 207 24 18 18 35 76 35 120 l0 41 -23 -27 c-47 -56 -123 -73 -194 -43 -81
34 -116 95 -117 205 0 114 37 194 107 233 42 23 139 23 178 0z m825 -158 c32
-96 60 -172 63 -170 2 3 30 81 61 173 l57 167 43 3 c23 2 46 0 51 -5 5 -5 -13
-65 -40 -133 -26 -69 -72 -186 -100 -260 -29 -74 -64 -154 -77 -177 -33 -58
-95 -93 -162 -93 l-52 0 0 46 c0 44 1 46 25 40 43 -11 89 16 113 68 12 25 22
51 22 58 0 7 -38 109 -85 226 -47 118 -85 218 -85 223 0 5 24 9 54 9 l55 0 57
-175z m777 161 c43 -17 93 -76 108 -124 6 -22 10 -73 8 -114 -5 -91 -34 -143
-106 -185 -41 -24 -59 -28 -123 -28 -64 0 -82 4 -122 28 -150 88 -140 347 16
418 50 22 171 25 219 5z m409 -61 c-2 -38 0 -112 3 -164 6 -80 11 -97 31 -118
45 -45 129 -23 154 42 5 14 10 91 10 171 l0 144 50 0 50 0 0 -230 0 -230 -50
0 c-50 0 -50 0 -50 32 l0 32 -27 -26 c-54 -52 -120 -61 -189 -28 -69 34 -78
64 -82 273 l-4 178 54 -3 53 -3 -3 -70z m1895 39 c55 -45 75 -85 81 -165 6
-91 -11 -149 -58 -198 -100 -103 -283 -89 -364 27 -26 37 -31 54 -34 117 -5
87 8 133 51 182 51 57 97 74 199 71 80 -3 89 -5 125 -34z m341 4 c5 -18 24
-95 43 -170 18 -76 36 -134 40 -130 4 4 23 68 44 142 20 74 40 146 44 160 7
22 13 25 57 25 l49 0 41 -163 c22 -89 43 -165 46 -167 3 -3 22 63 43 147 20
84 39 161 42 171 3 13 15 17 48 17 24 0 46 -4 49 -8 3 -5 -5 -45 -18 -88 -13
-43 -43 -143 -67 -222 l-43 -143 -54 3 -55 3 -41 152 c-23 83 -44 154 -47 157
-3 3 -25 -65 -50 -152 l-45 -157 -56 -3 -56 -3 -21 78 c-12 43 -39 139 -61
213 -22 74 -40 143 -40 153 0 14 9 17 50 17 47 0 49 -1 58 -32z m-10696 -335
c38 -34 9 -103 -43 -103 -29 0 -61 34 -61 65 0 50 64 74 104 38z m322 -2 c25
-28 17 -72 -16 -91 -25 -13 -31 -13 -55 0 -54 30 -34 110 27 110 15 0 35 -9
44 -19z m335 -7 c24 -31 16 -62 -23 -84 -23 -13 -29 -13 -53 0 -34 19 -43 69
-18 94 25 25 70 20 94 -10z m10815 7 c25 -28 17 -72 -16 -91 -25 -13 -31 -13
-55 0 -34 19 -43 69 -18 94 22 22 68 20 89 -3z m318 7 c27 -22 30 -53 7 -81
-23 -30 -54 -34 -87 -11 -26 18 -29 60 -5 86 19 21 62 24 85 6z m328 -8 c11
-11 20 -28 20 -37 0 -25 -40 -63 -67 -63 -29 0 -63 33 -63 62 0 53 71 77 110
38z"/>
<path d="M18014 10930 c-162 -64 -250 -303 -200 -541 33 -153 113 -242 241
-269 189 -39 343 83 376 300 15 100 7 282 -16 340 -27 66 -80 127 -138 157
-71 36 -189 42 -263 13z"/>
<path d="M7216 11359 c-10 -60 -6 -1173 4 -1188 13 -22 357 -9 440 16 261 80
398 264 417 558 16 267 -79 480 -257 574 -115 60 -234 81 -466 81 l-131 0 -7
-41z"/>
<path d="M9048 10494 c-176 -26 -235 -48 -276 -102 -24 -31 -27 -45 -27 -107
0 -62 3 -76 27 -107 40 -52 89 -73 173 -72 121 2 209 59 261 170 30 64 40 237
14 240 -8 1 -86 -9 -172 -22z"/>
<path d="M11055 10499 c-220 -31 -282 -56 -320 -130 -19 -36 -19 -132 0 -169
38 -74 143 -113 242 -90 32 7 70 18 84 26 45 23 114 109 132 164 17 53 24 221
10 219 -5 -1 -71 -10 -148 -20z"/>
<path d="M15305 10941 c-87 -21 -176 -103 -212 -195 -44 -110 -52 -251 -21
-375 25 -98 99 -188 188 -230 76 -35 211 -37 285 -3 133 61 196 184 197 384 2
226 -76 367 -226 413 -51 16 -159 18 -211 6z"/>
<path d="M8679 9321 c-38 -37 -39 -41 -39 -105 0 -106 40 -156 125 -156 87 0
131 60 122 169 -5 59 -8 68 -41 98 -30 27 -44 33 -82 33 -41 0 -51 -5 -85 -39z"/>
<path d="M10625 9361 c-27 -12 -61 -51 -70 -78 -6 -23 -6 -23 100 -23 l105 0
-6 28 c-13 56 -80 94 -129 73z"/>
<path d="M13125 9338 c-72 -56 -74 -213 -4 -262 33 -22 100 -21 131 4 38 30
51 71 46 151 -3 65 -6 74 -36 100 -41 36 -96 39 -137 7z"/>
<path d="M14673 9338 c-41 -29 -56 -65 -56 -131 0 -95 47 -147 133 -147 78 0
120 54 120 153 0 84 -44 138 -117 144 -39 3 -54 0 -80 -19z"/>
<path d="M16948 9345 c-31 -17 -68 -89 -68 -131 0 -92 51 -154 125 -154 24 0
55 5 68 11 74 34 88 197 22 259 -31 29 -108 37 -147 15z"/>
</g>
</svg>

		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
